'use strict';

module.exports = {

	isPortrait: function(width, height) {

		return width < height;

	},

	isLandscape: function(width, height) {

		return width > height;

	},

	isSquare: function(width, height) {

		return width === height;

	},

	scaleByWidth: function(srcWidth, srcHeight, scaledWidth) {

		return scaledWidth * srcHeight / srcWidth || 0;

	},

	scaleByHeight: function(srcWidth, srcHeight, scaledHeight) {

		return scaledHeight * srcWidth / srcHeight || 0;

	},

	contain: function(srcWidth, srcHeight, targetWidth, targetHeight) {

		var scaleByWidth = {};
		var scaleByHeight = {};
		var retData;

		// Scale image to target width, maintaining aspect ratio.
		scaleByWidth.width = targetWidth;
		scaleByWidth.height = this.scaleByWidth(srcWidth, srcHeight, targetWidth);

		// Scale image to target height, maintaining aspect ratio.
		scaleByHeight.width = this.scaleByHeight(srcWidth, srcHeight, targetHeight);
		scaleByHeight.height = targetHeight;

		// Determine which scaled direction wins. We don't want any overflow.
		if (scaleByWidth.height <= targetHeight) {

			// Scaling by width didn't cause overflow.
			retData = scaleByWidth;
			retData.scaledBy = 'width';

		} else {

			// Scaling by width did cause overflow, scale by height instead.
			retData = scaleByHeight;
			retData.scaledBy = 'height';

		}

		return retData;

	},

	cover: function(srcWidth, srcHeight, targetWidth, targetHeight) {

		var scaleByWidth = {};
		var scaleByHeight = {};
		var retData;

		// Scale image to target width, maintaining aspect ratio.
		scaleByWidth.width = targetWidth;
		scaleByWidth.height = this.scaleByWidth(srcWidth, srcHeight, targetWidth);

		// Scale image to target height, maintaining aspect ratio.
		scaleByHeight.width = this.scaleByHeight(srcWidth, srcHeight, targetHeight);
		scaleByHeight.height = targetHeight;

		// Determine which scaled direction wins. We want the entire target
		// to be covered/filled.
		if (scaleByWidth.height >= targetHeight) {

			// Scaling by width filled the target.
			retData = scaleByWidth;
			retData.scaledBy = 'width';

		} else {

			// Scaling by width did not fill the target, scale by height instead.
			retData = scaleByHeight;
			retData.scaledBy = 'height';

		}

		// Determine any cropping/overflow.
		retData.xOverflow = retData.width - targetWidth;
		retData.yOverflow = retData.height - targetHeight;

		// Determine offset amounts to center within target.
		retData.xOffset = -retData.xOverflow / 2;
		retData.yOffset = -retData.yOverflow / 2;

		return retData;

	}

};
