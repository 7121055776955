
; require("/srv/www/html/client/apps/portfolio/src/js/slideshow.js");
; var __browserify_shim_require__=require;(function browserifyShim(module, define, require) {
/* global core: false */
/* global slideshow: false */

(function(api, $, undefined){

	var settings = {},

		dom = {},

		state = {},

		saveSettings = function() {

			settings = slideshow.settings;

		},

		setupDom = function() {

			dom = {
				document: core.dom.getElement({ key: "document" }),
				captionContainer: $("<div id='captionContainer' />"),
				title: $("<span id='title' />"),
				caption: $("<span id='caption' />"),
				activeImageWrapper: core.dom.getElement({ key: "activeImageWrapper" })
			};

		},

		setupInitialState = function(){

			dom.captionContainer.addClass(slideshow.getColorThemeClass(settings.captionControlColorKey));

			state = {
				defaultCaptionPosition: {}
			};

		},

		injectMarkup = function(){

			dom.title.prependTo(dom.captionContainer);

			dom.caption.appendTo(dom.captionContainer);

			appendCaptionToTarget($(settings.captionTarget));

		},

		appendCaptionToTarget = function($target) {

			$target.append(dom.captionContainer.detach());

		},

		addEventHandlers = function(){

			dom.document.on("click.captions", "#captionContainer", function(e){

				processCaptionClick($(this));
				e.stopPropagation();

			});

			dom.document.on("slideshow_active_image_updated.captions", function(e){

				var isFirstImg = e.prevImgObj === undefined;

				setupCaptionsPinnedToImg(isFirstImg, e.activeImgObj).then(function() {

					hideCaptionText();

					setupCaptions(e.activeImgObj);

				});

			});

			dom.document.on("slideshow_images_markup_injected.captions", function() {

				// The images markup may or may not be ready when this module
				// is setup, so listen for images to be ready and append the
				// caption to the first image if necessary.

				var $firstImgWrapper;

				if (isCaptionPinnedToImg()) {

					$firstImgWrapper = $(core.dom.getElement({key: "imageWrappers", refresh: true})[0]);

					appendCaptionToTarget($firstImgWrapper);

				}

			});

		},

		processCaptionClick = function($container){

			var url = $container.attr("data-url"),
				menuID = $container.attr("data-menu-id"),
				isExternalUrl = url.substr(0, 1) !== "#";

			// slideshow.pause;

			if(url){

				if(isExternalUrl){
					goToExternalUrl(url);
				}
				else {
					goToInternalUrl(menuID, url);
				}

			}

		},

		goToExternalUrl = function(url){
			url = core.util.addHttpProtocol(url);
			window.open(url);
		},

		goToInternalUrl = function(menuID, url){

			var $link = $("a[data-page=" + menuID + "]");

			$link.click();
			window.location.hash = url;

		},

		saveDefaultCaptionPosition = function() {

			var props = ["top", "right", "bottom", "left"];

			$.each(props, function(index, value) {

				// Chrome and IE return the string "auto", so this will resolve to NaN. FireFox
				// returns integers for all values.
				state.defaultCaptionPosition[value] = parseInt(dom.captionContainer.css(value), 10);

			});

		},

		setupCaptionsPinnedToImg = function(isFirstImg, next) {

			return $.Deferred(function(dfd) {

				var $container = dom.captionContainer;

				if (!isCaptionPinnedToImg()) {

					dfd.resolve();

					return;

				}

				if (isFirstImg) {

					saveDefaultCaptionPosition();

					adjustCaptionPosition(next);

					dfd.resolve();

				} else {

					$container.animate({opacity: 0}, 400, function() {

						appendCaptionToTarget(next.wrapper);

						adjustCaptionPosition(next);

						dfd.resolve();

					});

				}

			}).promise();

		},

		adjustCaptionPosition = function(next) {

			var $container = dom.captionContainer,
				$contentWrapper,
				contentSize,
				wrapperSize,
				adjustByAxis = function(axis) {

					var scaleOffset,
						dimension = axis === "x" ? "width" : "height",
						cssProp,
						axisProps = axis === "x" ? ["left", "right"] : ["top", "bottom"];

					scaleOffset = (wrapperSize[dimension] - contentSize[dimension]) / 2;

					scaleOffset = scaleOffset < 0 ? 0 : scaleOffset;

					// Determine which property to adjust.
					if (isNaN(state.defaultCaptionPosition[axisProps[0]])) {

						// Handle "auto" left/top.

						cssProp = axisProps[1];

					} else if (isNaN(state.defaultCaptionPosition[axisProps[1]])) {

						// Handle "auto" right/bottom.

						cssProp = axisProps[0];

					} else if (state.defaultCaptionPosition[axisProps[0]] <= state.defaultCaptionPosition[axisProps[1]]) {

						// Assume "left/top" positioning because the caption is closer
						// to the left/top than the right/bottom.

						cssProp = axisProps[0];

					} else {

						cssProp = axisProps[1];

					}

					// Set/reset the position of the caption.
					$container.css(cssProp, state.defaultCaptionPosition[cssProp] + scaleOffset);

				};

			if (!isCaptionPinnedToImg() || slideshow.isCPanelSettingFit() || !slideshow.hasFillSupport()) {

				// No adjustments are necessary because the
				// caption is not pinned to the image, the mode is set to fit or
				// this template does not support fill.
				return;

			}

			// If we've gotten this far, captions are enabled, the caption is pinned
			// to the image, the mode is in fill and the template supports
			// fill. Now determine if the image/wrapper has been scaled outside of
			// the viewable content area.

			$contentWrapper = core.dom.getElement({key: "contentWrapper"});

			contentSize = {
				width: $contentWrapper.width(),
				height: $contentWrapper.height()
			};

			wrapperSize = next.scaledWrapperDimensions;

			// The only time an image will be cropped on the x-axis is in a Reel gallery b/c
			// only the Reel gallery will fill by height. Since only the right side of the
			// image will be cropped, no caption adjustment is necessary.

			// adjustByAxis("x");
			adjustByAxis("y");

		},

		isCaptionPinnedToImg = function() {

			return settings.captionTarget === dom.activeImageWrapper.selector;

		},

		hideCaptionText = function() {

			var $title = dom.title,
				$caption = dom.caption;

			$title.css({ opacity: 0 });
			$caption.css({ opacity: 0 });

		},

		setupCaptions = function(next){

			var img = next,
				title = img.title,
				caption = img.caption,
				url = img.url,
				menuID = img.menuID,
				menuType = img.menuType,
				linkClass = typeof url !== "undefined" && url.length ? "link" : "",
				$title = dom.title,
				$caption = dom.caption,
				$container = dom.captionContainer,
				contHeight,
				evenNumContHeight;

			$container
				.removeClass("link")
				.addClass(linkClass)
				.addClass(settings.controlThemeClass);

			if(caption || title){

				$title.text(title);
				$caption.text(caption);
				$container.attr("data-url", url);
				$container.attr("data-menu-id", menuID);
				$container.attr("data-menu-type", menuType);

				// If the image we are going to hasn't been downloaded yet,
				// the wrapper will be hidden and the height calculations
				// below will not work. In order to always get proper height
				// calculations, we must unhide the wrapper temporarily.

				if (img.wrapper.css("display") === "none") {

					img.wrapper
						.css({opacity: 0})
						.show();

					contHeight = $caption.height() + $title.height();

					img.wrapper
						.hide()
						.css({opacity: 1});

				} else {

					contHeight = $caption.height() + $title.height();

				}

				evenNumContHeight = contHeight % 2 ? contHeight + 1 : contHeight; // oddly, the animation is jerky in Chrome when the captionContainer height is an odd number, this hack ensures an even number

				$container.stop(true, true).show().animate({

					height: evenNumContHeight,
					opacity: 1

				}, 400, function(){

					$title.animate({ opacity: 1 });
					$caption.animate({ opacity: 1 });

				});

			}

			else{

				$title.text("");
				$caption.text("");
				$container.animate({ opacity: 0 });

			}

		},

		destroy = function(duration) {

			return $.Deferred(function(dfd) {

				dom.document.off(".captions");

				dom.captionContainer.animate({opacity: 0}, duration, function() {

					dom.captionContainer.remove();

					dfd.resolve();

				});

			}).promise();

		};


	api.init = function(){

		saveSettings();

		setupDom();

		setupInitialState();

		injectMarkup();

		addEventHandlers();

		slideshow.addCleanupItem(destroy);

		slideshow.triggerModuleReady("captions");

	};

}(window.slideshow.captions = window.slideshow.captions || {}, jQuery));
}).call(global, module, undefined, undefined);
